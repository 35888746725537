import React, { useCallback, useState } from "react";
import "./styles/global.sass";
import styles from "./styles/app.module.sass";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import HomePage from "./components/HomePage";
import HireMe from "./components/HireMe";
import AboutMe from "./components/AboutMe";
import NotFound from "./components/not-found";

const {
  wrapper,
  container,
  navBar,
  menu,
  links,
  navLink,
  headerContainer,
  logo,
  npm,
  tagline,
  popoutMenu,
  menuOpened,
} = styles;

function App() {
  const [menuVisible, setMenuVisible] = useState(false);

  const handleMenuClick = useCallback(() => {
    setMenuVisible(!menuVisible);
  }, [setMenuVisible, menuVisible]);

  return (
    <Router>
      <div className={wrapper}>
        <div className={`${container} ${menuVisible ? menuOpened : ""}`}>
          <div className={navBar}>
            <div className={links}>
              <Link to={"/"} className={navLink}>
                shibby
              </Link>
              <Link to="/hire-me" className={navLink}>
                Hire Me
              </Link>
              <Link to="/about-me" className={navLink}>
                About
              </Link>
            </div>
            <div className={menu} role="button" onClick={handleMenuClick}>
              <div />
              <div />
              <div />
            </div>
          </div>
          <div className={headerContainer}>
            <pre className={logo}>./danny</pre>
            <div>
              <pre className={npm}>npm install danny --save-dev</pre>
            </div>
            <div className={tagline}>
              ...don't actually install that.
              <br />
              Welcome to my portfolio.
            </div>
          </div>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/hire-me" component={HireMe} />
            <Route path="/about-me" component={AboutMe} />
            <Route path="*" component={NotFound} />
          </Switch>
        </div>
        <div className={`${popoutMenu} ${menuVisible ? menuOpened : ""}`}>
          <Link to={"/"} className={navLink} onClick={handleMenuClick}>
            shibby
          </Link>
          <Link to="/hire-me" className={navLink} onClick={handleMenuClick}>
            Hire Me
          </Link>
          <Link to="/about-me" className={navLink} onClick={handleMenuClick}>
            About
          </Link>
        </div>
      </div>
    </Router>
  );
}

export default App;
