const projects = [
  {
    key: 1,
    name: "Javascript Slots",
    desc: "It's almost fun! I started this project as a study into Javascript. I really enjoyed working on it and have implemented things that I have learned into other projects. This also gave me a basis in which to working in other platforms based on JS, such as Node.js, which this site is built upon.",
    url: "public/projects/javascript-slots/index.html",
    ghurl: "https://www.github.com/smilepl0x/javascript-slots",
    languages: "Vanilla Javascript, HTML, CSS/Bootstrap",
  },
  {
    key: 2,
    name: "Scale Generator",
    desc: "An application that generates scales based on user selections. This is a project that I am taking on with a musician friend of mine. I know very little about music theory, so I took this as an opportunity to simultanously produce a useful application while expanding my knowledge of how music works.",
    url: "public/projects/scale-generator/index.html",
    ghurl: "https://www.github.com/smilepl0x/scale-generator",
    languages: "Javascript, JQuery, HTML, CSS/Bootstrap",
  },
  {
    key: 3,
    name: "starhe.art",
    desc: "An web applicaton that watermarks uploaded photos. This was a collaboration with a developer friend who needed a way to watermark her photos. I was responsible for UX design. I used this opportunity to stretch my legs in Bootstrap. I found this to be the best option for creating a beautiful experience while maintaining the simplicity of the application.",
    url: "http://starhe.art",
    ghurl: "https://www.github.com/stellarain/starheart",
    languages: "PHP, HTML, CSS/Bootstrap",
  },
];

export default projects;
