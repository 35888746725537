import React from "react";
import styles from "../styles/homepage.module.sass";
import Project from "./Project";
import projectsArray from "../projectsArray";

const { projects, heading } = styles;
function HomePage() {
  return (
    <>
      <div className={projects}>
        <div className={heading}>
          <p>Showcase</p>
        </div>
        {projectsArray.map((p) => (
          <Project
            title={p.name}
            desc={p.desc}
            url={p.url}
            ghurl={p.ghurl}
            languages={p.languages}
          />
        ))}
      </div>
    </>
  );
}

export default HomePage;
