import React, { useState } from "react";
import styles from "../styles/hireme.module.sass";

const { container, form, radioGroup } = styles;

function HireMe() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephoneNumber] = useState("");
  const [typeOfWork, setTypeOfWork] = useState("Full Time");
  const [aboutProject, setAboutProject] = useState("");
  const [isHidden, setIsHidden] = useState(true);
  const [message, setMessage] = useState(
    "Thanks for the email! I'll reply soon!"
  );

  const postData = (form) => {
    //console.log(JSON.stringify(form));
    fetch("/sendmail", {
      method: "POST",
      body: JSON.stringify(form),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          return response.json();
        } else {
          setMessage(
            "There was a problem sending the message. Sorry about that!"
          );
          throw Error(response.statusText);
        }
      })
      .then(() => {
        console.log("Success");
        handleClear();
      })
      .catch((e) => console.log(e))
      .finally(() => setIsHidden(false));
  };

  const handleChange = (event) => {
    let value = event.target.value;
    switch (event.target.name) {
      case "name":
        value = value.replace(/[^\s\w]|\d/g, "");
        setName(value);
        break;
      case "email":
        value = value.replace(/[^\w@.]|/g, "");
        setEmail(value);
        break;
      case "telephone":
        value = value.replace(/[^\d-]/g, "");
        setTelephoneNumber(value);
        break;
      case "typeOfWork":
        setTypeOfWork(value);
        break;
      case "aboutProject":
        value = value.replace(/[^\w\s]/g, "");
        setAboutProject(value);
        break;
      default:
    }
  };

  const handleSubmit = (event) => {
    if (name !== "" && email !== "" && telephone !== "") {
      const form = {
        name,
        email,
        telephone,
        typeOfWork,
        aboutProject,
      };
      postData(form);
    }
    event.preventDefault();
  };

  const handleClear = () => {
    setName("");
    setEmail("");
    setTelephoneNumber("");
    setTypeOfWork("Full Time");
    setAboutProject("");
  };

  const workTypes = [
    "Full Time",
    "Part-Time",
    "Contract",
    "Consultation",
    "Something Else",
  ];

  return (
    <div className={container}>
      <h2>Drop Me a Line</h2>
      <p className="text-muted">
        Responses will be recieved from dfaught@protonmail.com
      </p>
      <form className={form} onSubmit={handleSubmit}>
        <input
          required
          type="text"
          name="name"
          placeholder="Full Name"
          value={name}
          onChange={handleChange}
        />
        <input
          required
          type="tel"
          name="telephone"
          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
          placeholder="Telephone (123-456-7890)"
          value={telephone}
          onChange={handleChange}
        />
        <input
          required
          type="text"
          name="email"
          placeholder="Email"
          value={email}
          onChange={handleChange}
        />
        <div className={radioGroup}>
          {workTypes.map((workType) => (
            <>
              <input
                type="radio"
                id={workType}
                value={workType}
                name="workType"
                onClick={handleChange}
              />
              <label htmlFor={workType}>{workType}</label>
            </>
          ))}
        </div>
        <textarea
          required
          as="textarea"
          name="aboutProject"
          placeholder="A little about your query"
          value={aboutProject}
          onChange={handleChange}
        />
        <button onClick={handleSubmit}>Submit</button>
        <p className={isHidden ? "hidden" : ""}>{message}</p>
      </form>
    </div>
  );
}

export default HireMe;
