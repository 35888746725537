import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function NotFound() {
  return (
    <Container>
      <Row>
        <Col className="text-center">
          <h1>404</h1>
          <h4>That's an error.</h4>
        </Col>
      </Row>
    </Container>
  );
}

export default NotFound;
