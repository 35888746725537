import React from "react";
import styles from "../styles/project.module.sass";

const {
  card,
  cardHeader,
  cardBody,
  buttonContainer,
  button,
  secondary,
  cardFooter,
} = styles;

function Project(props) {
  return (
    <div className={card}>
      <div className={cardHeader}>
        <h3>{props.title}</h3>
      </div>
      <div className={cardBody}>
        <p>{props.desc}</p>
      </div>
      <div className={buttonContainer}>
        <a className={`${button}`} href={props.url}>
          See It
        </a>
        <a className={`${button} ${secondary}`} href={props.ghurl}>
          Repo
        </a>
      </div>
      <div className={cardFooter}>Languages: {props.languages}</div>
    </div>
  );
}

export default Project;
