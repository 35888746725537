import React from "react";
import styles from "../styles/aboutme.module.sass";

const { container, meFace, stuff } = styles;
const { PUBLIC_URL } = process.env;
function AboutMe() {
  return (
    <div className={container}>
      <img
        alt="me face!"
        className={meFace}
        src={PUBLIC_URL + "/images/df-face.jpg"}
      />
      <div className={stuff}>
        <h3>
          Hi, I'm Danny!
          <span role="img" aria-label="">
            {" "}
            👍
          </span>
        </h3>
        <p>
          I'm a software engineer. I'd like to tell you that I am a full-stack
          genius. A brilliant prodigy who has been coding since he was in
          diapers. A hacker man, a lone wolf, a night-owl who can push out a
          fully functional app in the span of an hour an a half. But that's
          really not me. I am, however, a fairly normal guy who has fostered a
          passion for software and problem-solving for as long as I can
          remember. I love creating applications and have used a wide variety of
          front-end and back-end languages.
        </p>
        <p>Here's some stuff that I like:</p>
        <ul style={{ listStyleType: "none", margin: "0", padding: "0" }}>
          <li>HTML</li>
          <li>CSS</li>
          <li>JavaScript</li>
          <li>Node.js</li>
          <li>React</li>
          <li>Java</li>
          <li>C++</li>
          <li>Python</li>
        </ul>
      </div>
    </div>
  );
}

export default AboutMe;
